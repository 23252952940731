<template>
	<div id="clinic-form">
		<ValidationObserver ref="form">
			<form @submit.prevent="applyForm">
				<div class="clinic-form__header mb-3">
					<h5 v-if="!isUpdate">{{ $t("ClinicTitle.CreateClinic") }}</h5>

					<template v-else>
						<h5>{{
							$t("ClinicTitle.ClinicName", {
								name: clinicName,
							})
						}}</h5>
						<star-rating
							v-model="clinicForm.rating"
							:increment="0.01"
							:star-size="15"
							:show-rating="false"
							read-only
							active-color="rgb(255, 193, 32)"
						></star-rating>
					</template>
				</div>

				<div class="clinic-form__container">
					<!-- Clinic Status -->
					<div class="form-group required">
						<label for="status" class="form-group__label col-form-label">{{
							$t("ClinicForm.Status")
						}}</label>
						<div class="form-group__input">
							<ValidationProvider
								ref="admin-status"
								v-slot="{ classes, errors }"
								name="clinic_status"
								rules="required"
							>
								<div :class="[classes]">
									<multiselect
										id="status"
										v-model="clinicForm.status"
										:custom-label="labelStatus"
										:options="status"
										:close-on-select="true"
										:show-labels="false"
										:allow-empty="false"
										:searchable="false"
										placeholder=""
										@input="selectStatus"
										@close="checkValidate('admin-status')"
									>
										<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
										<template #noResult>{{ $t("DropDown.NoResult") }}</template>
									</multiselect>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- Clinic Code -->
					<div class="form-group required">
						<label for="code" class="form-group__label col-form-label">{{
							$t("ClinicForm.ClinicCode")
						}}</label>
						<div class="form-group__input">
							<ValidationProvider
								ref="admin-code"
								v-slot="{ classes, errors }"
								name="clinic_code"
								rules="required|alpha_num"
							>
								<div :class="[classes]">
									<input
										id="code"
										v-model.trim="clinicForm.code"
										type="text"
										class="form-control"
										:maxlength="codeLength"
									/>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- Clinic Name -->
					<div class="form-group required">
						<label for="name" class="form-group__label col-form-label">{{
							$t("ClinicForm.ClinicName")
						}}</label>
						<div class="form-group__input">
							<ValidationProvider
								ref="admin-name"
								v-slot="{ classes, errors }"
								name="clinic_name"
								rules="required"
							>
								<div :class="[classes]">
									<input
										id="name"
										v-model.trim="clinicForm.name"
										type="text"
										class="form-control"
										:placeholder="$t('ClinicForm.PlaceHolderName')"
										:maxlength="nameLength"
									/>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- Clinic Type -->
					<div class="form-group required">
						<label for="type" class="form-group__label col-form-label">{{
							$t("ClinicForm.Type")
						}}</label>
						<div class="form-group__input">
							<ValidationProvider
								ref="admin-type"
								v-slot="{ classes, errors }"
								name="clinic_type"
								rules="required"
							>
								<div :class="[classes]">
									<multiselect
										id="type"
										v-model="clinicForm.type"
										:custom-label="labelType"
										:options="types"
										:close-on-select="true"
										:show-labels="false"
										:allow-empty="false"
										:searchable="false"
										placeholder=""
										@close="checkValidate('admin-type')"
									>
										<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
										<template #noResult>{{ $t("DropDown.NoResult") }}</template>
									</multiselect>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- Services -->
					<div class="form-group required service">
						<label for="services" class="form-group__label">{{
							$t("AppointmentForm.Services")
						}}</label>
						<div class="form-group__input">
							<ValidationProvider
								v-slot="{ classes, errors }"
								name="appointment_services"
								rules="required"
							>
								<div :class="[classes]">
									<div class="grid">
										<div v-for="answer in services" :key="answer.id" class="form-check">
											<input
												:id="`service-${answer.id}`"
												v-model="clinicForm.serviceIds"
												class="form-check-input"
												type="checkbox"
												:value="answer.id"
											/>

											<label class="form-check-label" :for="`service-${answer.id}`">{{
												answer.name
											}}</label>
										</div>
									</div>
									<div class="error-mess mt-0">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- Clinic Logo -->
					<div class="form-group required">
						<label for="logo" class="form-group__label">{{ $t("ClinicForm.Logo") }}</label>
						<div class="form-group__input">
							<ValidationProvider
								ref="admin-logo"
								v-slot="{ classes, errors }"
								name="clinic_logo"
								:rules="{ required: !clinicForm.logo, image: true }"
							>
								<div :class="[classes]">
									<img id="logo" :src="picture" class="logo" accept="image/*" @click="onPickFile" />
									<div class="small-text mt-2">( {{ $t("ClinicForm.MaxUpload") }} )</div>

									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
							<input ref="myFiles" type="file" class="d-none" @change="previewFiles" />
						</div>
					</div>

					<!-- Clinic Address -->
					<div class="form-group required">
						<label for="address" class="form-group__label col-form-label">{{
							$t("ClinicForm.Address")
						}}</label>
						<div class="form-group__input">
							<ValidationProvider
								ref="admin-address"
								v-slot="{ classes, errors }"
								name="clinic_address"
								rules="required"
							>
								<div :class="[classes]">
									<textarea
										id="address"
										v-model.trim="clinicForm.address"
										class="form-control"
										rows="3"
										:maxlength="addressLength"
									></textarea>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- Clinic Province -->
					<div class="form-group required">
						<label for="city" class="form-group__label col-form-label">{{
							$t("ClinicForm.Province")
						}}</label>
						<div class="form-group__input">
							<ValidationProvider
								ref="admin-city"
								v-slot="{ classes, errors }"
								name="clinic_city"
								rules="required"
							>
								<div :class="[classes]">
									<multiselect
										id="city"
										v-model="clinicForm.cityId"
										track-by="id"
										label="name"
										:options="listCities"
										:close-on-select="true"
										:show-labels="false"
										:allow-empty="false"
										:searchable="true"
										:internal-search="false"
										placeholder=""
										@open="getCities"
										@close="checkValidate('admin-city')"
										@search-change="searchChange('admin-city', $event)"
									>
										<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
										<template #noResult>{{ $t("DropDown.NoResult") }}</template>
									</multiselect>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- Clinic Phone -->
					<div class="form-group required">
						<label for="phone" class="form-group__label col-form-label">{{
							$t("ClinicForm.Phone")
						}}</label>
						<div class="form-group__input">
							<ValidationProvider
								ref="admin-phone"
								v-slot="{ classes, errors }"
								name="clinic_phone"
								rules="required|integer"
							>
								<div :class="[classes]">
									<input
										id="phone"
										v-model.trim="clinicForm.phone"
										type="text"
										class="form-control"
										:maxlength="phoneMaxLength"
										@keydown="checkValidPhone($event)"
									/>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- Clinic Website -->
					<div class="form-group required">
						<label for="website" class="form-group__label col-form-label">{{
							$t("ClinicForm.Website")
						}}</label>
						<div class="form-group__input">
							<ValidationProvider
								ref="admin-website"
								v-slot="{ classes, errors }"
								name="clinic_website"
								rules="required"
							>
								<div :class="[classes]">
									<input
										id="website"
										v-model.trim="clinicForm.website"
										type="text"
										class="form-control"
										:maxlength="websiteLength"
									/>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- Clinic Working Time -->
					<div class="form-group required">
						<label for="working-hour" class="form-group__label col-form-label">{{
							$t("ClinicForm.WorkingTime")
						}}</label>
						<div class="form-group__input">
							<ValidationProvider
								ref="admin-working-hour"
								v-slot="{ classes, errors }"
								name="clinic_working_hour"
								rules="required"
							>
								<div :class="[classes]">
									<input
										id="working-hour"
										v-model.trim="clinicForm.workingHour"
										type="text"
										class="form-control"
										:maxlength="workingTimeLength"
									/>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- Clinic Short Description -->
					<div class="form-group required">
						<label for="short-intro" class="form-group__label col-form-label">{{
							$t("ClinicForm.ShortDesClinic")
						}}</label>
						<div class="form-group__input">
							<ValidationProvider
								ref="admin-short-intro"
								v-slot="{ classes, errors }"
								name="clinic_short_intro"
								rules="required"
							>
								<div :class="[classes]">
									<textarea
										id="admin-short-intro"
										v-model.trim="clinicForm.shortIntro"
										class="form-control"
										rows="3"
										:maxlength="introLength"
									></textarea>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- Clinic Email -->
					<div class="form-group">
						<label for="email" class="form-group__label col-form-label">{{
							$t("ClinicForm.Email")
						}}</label>
						<div class="form-group__input">
							<ValidationProvider
								ref="admin-email"
								v-slot="{ classes, errors }"
								name="clinic_email"
							>
								<div :class="[classes]">
									<input
										id="admin-email"
										v-model.trim="clinicForm.email"
										type="text"
										class="form-control"
										:maxlength="defaultLength"
									/>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- Clinic Feedback -->
					<div class="form-group required">
						<label for="feedback" class="form-group__label col-form-label">{{
							$t("ClinicForm.LinkRating")
						}}</label>
						<div class="form-group__input">
							<ValidationProvider
								ref="admin-feedback"
								v-slot="{ classes, errors }"
								name="clinic_feedback"
								rules="required"
							>
								<div :class="[classes]">
									<input
										id="admin-feedback"
										v-model.trim="clinicForm.feedBack"
										type="text"
										class="form-control"
										:maxlength="websiteLength"
									/>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>
				</div>

				<div v-if="isInvalidForm" class="text-center text-danger mb-2">{{ $t("Form.Error") }}</div>
				<div class="clinic-form__action text-center">
					<CButton v-bind="{ variant: 'outline' }" color="info" type="submit">{{
						textSubmit
					}}</CButton>
					<CButton
						v-if="isUpdate && isSystemAdmin"
						v-bind="{ variant: 'outline' }"
						color="warning"
						@click="toggleDeleteModal(true)"
						>{{ $t("Button.Delete") }}</CButton
					>
					<CButton v-bind="{ variant: 'outline' }" color="danger" @click="cancelForm">{{
						$t("Button.Exit")
					}}</CButton>
				</div>
			</form>
		</ValidationObserver>

		<!-- Show modal when change admin status -->
		<CustomModal
			v-if="isShowStatusModal"
			:is-show-submit="false"
			:cancel-color="'outline-custom'"
			:cancel-text="$t('Button.OK')"
			@cancel-modal="confirmStatus"
		>
			<template #content>
				{{ $t("ClinicForm.ConfirmStatus") }}
			</template>
		</CustomModal>

		<!-- Show modal when delete form -->
		<CustomModal
			v-if="isShowDeleteModal"
			:submit-text="$t('Button.Confirm')"
			:cancel-text="$t('Button.Close')"
			@submit-modal="deleteMessage"
			@cancel-modal="toggleDeleteModal(false)"
		>
			<template #content>
				<p>{{ $t("Button.AreYouSure") }}</p>
				<span>{{ $t("ClinicForm.DeleteDescription") }}</span>
			</template>
		</CustomModal>

		<!-- Show modal when cancel if clinicForm was changed -->
		<CustomModal
			v-if="isShowDiscardModal"
			:submit-text="$t('Button.Confirm')"
			:cancel-text="$t('Button.Close')"
			@submit-modal="discardChanges"
			@cancel-modal="toggleDiscardModal(false)"
		>
			<template #content>
				<p>{{ $t("Button.DiscardChanges") }}</p>
				<span>{{ $t("Button.DiscardContent") }}</span>
			</template>
		</CustomModal>
	</div>
</template>

<script>
import { CLINIC_STATUS, CLINIC_TYPE, INPUT_LENGTH } from "@/shared/plugins/constants"
import picture from "@/assets/img/clinic.png"
import { GET_CITIES, GET_SERVICES } from "./../store/action-types"
import {
	CHANGE_STATUS_DISCARD_MODAL,
	CHANGE_STATUS_DELETE_MODAL,
	CHANGE_STATUS_CLINIC_MODAL,
} from "./../store/mutation-types"
import { createNamespacedHelpers, mapState } from "vuex"
const { mapActions, mapMutations } = createNamespacedHelpers("clinics")
import CustomToastr from "@/shared/plugins/toastr"
import { get, isEqual } from "lodash-es"
import { USER_ROLE } from "../../../shared/plugins/constants"

export default {
	name: "ClinicForm",

	components: {},

	props: {
		isUpdate: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			originForm: {
				status: null,
				code: null,
				name: null,
				logo: null,
				cityId: null,
				address: null,
				phone: null,
				website: null,
				workingHour: null,
				shortIntro: null,
				email: "",
				feedBack: null,
				serviceIds: [],
			},

			clinicForm: {},

			isInvalidForm: false,

			status: [CLINIC_STATUS.ACTIVE, CLINIC_STATUS.INACTIVE],

			types: [CLINIC_TYPE.TU_NHAN, CLINIC_TYPE.CONG, CLINIC_TYPE.DNXH, CLINIC_TYPE.CBO],

			listCities: [],
			codeLength: INPUT_LENGTH.CODE,
			nameLength: INPUT_LENGTH.CLINIC_NAME,
			phoneMaxLength: process.env.VUE_APP_PHONE_MAX_LENGTH,
			defaultLength: process.env.VUE_APP_INPUT_MAX_LENGTH,
			addressLength: process.env.VUE_APP_ADDRESS_MAX_LENGTH,
			websiteLength: INPUT_LENGTH.WEBSITE,
			workingTimeLength: INPUT_LENGTH.WORKING_TIME,
			introLength: INPUT_LENGTH.INTRO,
			picture,
			clinicName: null,
			maxlength: process.env.VUE_APP_DESCRIPTION_MAX_LENGTH,
		}
	},

	computed: {
		...mapState("clinics", [
			"recentClinic",
			"cities",
			"isShowDiscardModal",
			"isShowDeleteModal",
			"isShowStatusModal",
			"services",
		]),
		...mapState("authentication", ["currentUser"]),

		textSubmit: function () {
			return this.isUpdate ? this.$t("Button.SaveChanges") : this.$t("Button.CreateNew")
		},

		isSystemAdmin: function () {
			return this.currentUser?.role === USER_ROLE.SYSTEM_ADMIN
		},
	},

	watch: {
		recentClinic: function () {
			this.setUpData()
		},

		cities: function (val) {
			this.listCities = [...val]
		},
	},

	created() {
		this.GET_SERVICES()

		this.setUpData()
	},

	beforeDestroy() {},

	methods: {
		get,

		...mapActions({ GET_CITIES, GET_SERVICES }),
		...mapMutations({
			CHANGE_STATUS_DISCARD_MODAL,
			CHANGE_STATUS_DELETE_MODAL,
			CHANGE_STATUS_CLINIC_MODAL,
		}),

		setUpData() {
			if (this.isUpdate) {
				this.clinicForm = { ...this.recentClinic, cityId: this.recentClinic?.city }
				this.clinicForm.serviceIds = this.clinicForm.service?.map(item => item.id)

				this.picture = this.recentClinic?.logo || this.picture
				this.clinicName = this.recentClinic?.name
			} else this.clinicForm = { ...this.originForm }
		},

		applyForm: async function () {
			const valid = await this.$refs.form.validate()
			if (!valid) {
				this.isInvalidForm = true
				return
			}
			this.isInvalidForm = false

			const params = {
				...this.clinicForm,
				cityId: this.clinicForm.cityId.id,
				serviceIds: this.clinicForm.serviceIds?.toString(),
			}

			const formData = new FormData()
			for (const key in params) {
				formData.append(key, params[key])
			}
			this.$emit("apply-form", formData)
		},

		cancelForm() {
			let isEq
			if (this.isUpdate) {
				const clinicForm = { ...this.clinicForm }
				clinicForm.city = clinicForm.cityId
				delete clinicForm.cityId

				isEq = isEqual(clinicForm, this.recentClinic)
			} else isEq = isEqual(this.clinicForm, this.originForm)

			if (!isEq) this.toggleDiscardModal(true)
			else this.$emit("cancel-form")
		},

		onPickFile() {
			this.$refs.myFiles.click()
		},

		previewFiles(evt) {
			this.$refs["admin-logo"].validate(evt)

			if (evt.target.files[0].size > 100000) {
				CustomToastr.error(this.$t("ClinicForm.ErrorUpload"))
				return
			}

			if (evt.target.files[0].type.search("image") > -1) {
				this.clinicForm.logo = evt.target.files[0]
				this.picture = URL.createObjectURL(this.clinicForm.logo)
			}

			if (!this.clinicForm.logo) this.$refs["admin-logo"].validate()
		},

		getCities() {
			this.GET_CITIES()
		},

		selectStatus(value) {
			if (value === CLINIC_STATUS.INACTIVE) this.CHANGE_STATUS_CLINIC_MODAL(true)
		},

		confirmStatus() {
			this.CHANGE_STATUS_CLINIC_MODAL(false)
		},

		discardChanges() {
			this.toggleDiscardModal(false)
			this.$emit("cancel-form")
		},

		toggleDiscardModal(status = false) {
			this.CHANGE_STATUS_DISCARD_MODAL(status)
		},

		deleteMessage() {
			this.$emit("delete-form", this.clinicForm)
			this.toggleDeleteModal(false)
		},

		toggleDeleteModal(status = false) {
			this.CHANGE_STATUS_DELETE_MODAL(status)
		},

		labelStatus(value) {
			return {
				[CLINIC_STATUS.ACTIVE]: this.$t("Status.Active"),
				[CLINIC_STATUS.INACTIVE]: this.$t("Status.Inactive"),
			}[value]
		},

		labelType(value) {
			return {
				[CLINIC_TYPE.TU_NHAN]: this.$t("ClinicForm.PrivateClinic"),
				[CLINIC_TYPE.CONG]: this.$t("ClinicForm.PublicClinic"),
				[CLINIC_TYPE.DNXH]: this.$t("ClinicForm.DNXHClinic"),
				[CLINIC_TYPE.CBO]: this.$t("ClinicForm.CBOClinic"),
			}[value]
		},

		searchChange(field, value) {
			if (!value || !value.length) {
				this.listCities = [...this.cities]
				return
			}

			const valueSearch = this.nomalizeText(value)
			this.listCities = this.cities.filter(({ name }) => {
				return this.nomalizeText(name).includes(valueSearch)
			})

			if (this.$refs[field] && value.length) this.$refs[field].reset()
			else this.checkValidate(field)
		},

		checkValidate(field) {
			if (this.$refs[field]) this.$refs[field].validate()
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";
.clinic-form {
	&__header {
		display: flex;
		flex-wrap: wrap;
		gap: 24px;
	}

	&__container {
		display: grid;
		grid-gap: 10px 20px;
		width: 100%;
		margin-bottom: 15px;
		background-color: $color-white;
		border-radius: 12px;

		.logo {
			width: 150px;
			height: 120px;
			-o-object-fit: contain;
			object-fit: contain;

			&:hover {
				cursor: pointer;
			}
		}
		.logo + span {
			word-break: break-all;
			&:hover {
				cursor: pointer;
			}
		}
	}

	&__action {
		display: flex;
		justify-content: space-between;

		@media (min-width: $xxs) {
			justify-content: center;

			.btn + .btn {
				margin-left: 40px;
			}
		}
	}
}

input[type="text"] {
	height: 35px !important;
}

.form-group {
	display: flex;
	flex-wrap: wrap;
	gap: 2%;
	margin-bottom: 0;

	&[stretch] {
		.form-group__input {
			flex: 0 0 100%;
			max-width: 100%;

			@media (min-width: $xs) {
				flex: 0 0 75%;
				max-width: 75%;
			}
		}
	}

	&__label {
		flex: 0 0 100%;
		max-width: 100%;

		@media (min-width: $xs) {
			flex: 0 0 18%;
			max-width: 18%;
		}
	}

	&__input {
		flex: 0 0 100%;
		max-width: 100%;

		@media (min-width: $xs) {
			flex: 0 0 75%;
			max-width: 75%;
		}

		@media (min-width: $s) {
			flex: 0 0 50%;
			max-width: 50%;
		}

		@media (min-width: $ms) {
			flex: 0 0 30%;
			max-width: 30%;
		}
	}
}

.form-group.required > label::after {
	content: "*";
	color: $color-red;
	margin-left: 2px;
}

.group-time {
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
	margin-bottom: 10px;

	&__text {
		align-self: center;
	}

	&__input {
		width: 75px;
	}

	&__add {
		text-decoration: underline;
		align-self: center;
	}
}

.group-time + .error-mess {
	position: relative;
	top: -8px;
}

/deep/ .multiselect {
	font-size: 14px;
	&__single {
		font-size: 14px;
	}

	&__tag {
		margin-bottom: 0;
	}
}

/deep/ .vue-star-rating {
	padding-top: 1px;
	align-self: start;

	.vue-star-rating-star {
		display: block;
	}
}

.grid {
	@include grid-between;
	grid-template-columns: 1fr;
	gap: 5px;

	.error-mess {
		grid-column: 1 / -1;
	}
}

.small-text {
	font-size: 12.5px;
}
</style>
